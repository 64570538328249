// $primary: #a51616;
$primary: #FAB075;	 /* MAIN COLOR */
$secondary: #009cb2; /* SECONDARY COLOR */
$blk: #333; /* DARK GRAY, REASON WHY IT'S SET TO #333 IS SO YOU CAN DARKEN IT IF NEEDED USING darken($blk, 10%); FOR EXAMPLE. YOU COULD ALSO USE 2 VARIABLES INSTEAD */
$wht: #fafafa; 
$border-radius: 0px; /* USE THIS TO SET THE BORDER RADIUS FOR BUTTONS */
$footerLinks: #009cb2;

select {
    -moz-appearance: none;
    text-indent: 0.01px;
    text-overflow: '';
}


.flash {
	display:none;
}

nav {
	z-index: 1000;
}

h1, h3 {font-family: 'Racing Sans One', sans-serif, 'Open Sans' !important;
	@media (max-width: 767px) {
		text-align: center;

	}

}

p {font-family: 'Oxygen', sans-serif !important;
	@media (max-width: 767px) {
		text-align: center;

	}}

a {font-family: 'Racing Sans One', sans-serif, 'Open Sans' !important;
color: #009cb2;}

a:hover {font-family: 'Racing Sans One', cursive !important;}


/*----------------- NAVIGATION --------------------- */

.navbar.navbar-default {background-image: url('../img/head-bg.png');
                         background-repeat: repeat-x;
                         height: 116px;
                         z-index: 1000;
                         border: 0px;
						 border-bottom-left-radius: 0px;
						 border-bottom-right-radius: 0px;

						 @media (max-width: 767px) {
							 	max-height: 100px;

						 }

}

.navbar-default .navbar-collapse {border-bottom: 0px;}

.navbar-brand {margin-top: 0px;
			   padding-top: 0px;
               }

.navbar-right {margin-top: 35px;

			  @media (max-width: 767px) {

				  margin-top: 15px;
			  }
}

.navbar-default .navbar-nav > li > a {font-size: 2em;
                                      color: #009cb2;
                                      border-bottom: 0px !important;

									  @media (max-width: 767px) { 
										background-color: white;
										margin-top: 0px;
										font-size: 1.5em !important;
									  }
									 @media (max-width: 1024px) {
							 			font-size: 1em;
									  }

}

.navbar-default .navbar-nav > li > a:hover {color: #FAB075 !important;

											@media (max-width: 767px) {
												background-color: #fff;

											}
}

.navbar-default .navbar-nav > li > a:focus {background-color: transparent !important;

											@media (max-width: 767px) {
													background-color: white !important;

											}
			}

.navbar-default .navbar-collapse {border: 0px;}



/** LOGIN FORM **/

@mixin btn1 {
	text-align: center;
	margin: 0 auto;
	border: 1px solid $primary;
	border-radius: $border-radius;
	background: $primary;
	color: $wht;
	padding: 0.5em 2em;

	&:hover {
		background: lighten($primary,10%);
		color: lighten($wht, 10%);
		box-shadow: 0px 0px 3px lighten($blk,10%);
	}
}

@mixin btn2 {
	@include btn1;
	background: darken($wht, 5%);
	color: $primary;
	border: none;

	&:hover {
		background: $wht;
		color: $primary;
		border: none;
	}
}

.modal-dialog {
	max-width: 350px;
	text-align: center;
	margin: 6em auto;
	
	.close {display: none;}

	.modal-content {
		color: $primary;
		
		h2 {
			text-align:center;
		}
	}

	input {
		border: none;
		border-bottom: 1px solid darken($primary,20%);
		text-align: center;
	}
	
	button {
		background: transparent;
		color: $wht;
		display: block;
		border:none;
		padding: 1em 2em;
		margin: 0 auto;

		&:hover {
			background: transparent;
			color: #fff;
			box-shadow: none;
			text-shadow: 0px 0px 3px $blk;
			border:none;
		}
	}
	
	input[type="submit"] {
		@include btn1;
		display: block;
		width: 75%;	
	}

}

.modal-header, .modal-footer {
	background: $primary;
	color: $wht;
}

input#username {
	margin-bottom: 20px;
}

/** END LOGIN FORM **/

footer {
	padding: 50px 0px 20px;
	background: $primary;
	color: $footerLinks;

	a {
		color: $footerLinks;

		&:hover {
			color: lighten($footerLinks, 10%);
		}
	}
}

@media (max-width: 1024) {
	input#username, input#password {
  		font-size: 16px;
	}
}

/* ---------- Header ------------ */

.header {
    background-image: url("../img/banner.jpg");
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-position: 100% 0%;
    z-index: -1000;
    background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    -webkit-background-size: cover; 
    background-color: #fff;
    margin-top: -50px;

        @media (max-width: 767px) {
                margin-top: -25px;
  }

        @media (max-width: 1024px) {
        margin-top: -25px;
        background-attachment: scroll;
        background-position: 75% 50%;

        }

@media (min-width: 1199px) and (orientation: landscape) {

	margin-top: -15px;
	margin-left: -20px;
}

}

.header-overlay {
    background-color: rgba(31, 31, 31, 0.75);
    padding: 200px 0px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
      border: 0px !important;
    margin-bottom: 0px !important;

    @media (max-width: 767px) {
            padding: 75px 0px;
    }

}

.logo {width: 100%;
    @media (max-width: 767px) {
		width: 100%;
        max-width: 241px;
        padding-top: 5px;
        padding-left: 5px;
    }
}

.logo-large {top: 0px;  
    display: block;
    margin: 0 auto;
    max-width: 100%;
	margin-bottom: 150px;

    @media (max-width: 767px) {
        top: 25px;
    }   

    @media (max-width: 1199px){
                color: #fff;
                font-size: 1.15em;
    }

}

.logo-large p { color: #fff;
                font-size: 1.5em;
                line-height: 45px;
                margin-bottom: 45px;
                padding-left: 10px;
                
                @media (max-width: 767px) {
                        padding-top: 45px;
                        text-align: center;
    }  
}

@media (max-width: 1199px) {


.logo-large p { color: #fff;
                font-size: 1.15em;
                line-height: 40px;
} 


}

.container-fluid {padding: 0px;}

/* =========== Buttons ============ */

.btn-download,
.btn-download:focus,
.btn-download:active,
.btn-download:visited
{
    background-color: #FAB075;
    border-color: #009cb2;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 30px;
    padding-right: 30px;
    font-size: 19px;
    text-transform: uppercase;
    border-radius: 3px;
    transition: all 1s;
    -webkit-transition: all 1s;
    -moz-transition: all 1s;
     -o-transition: all 1s;
}
.btn-download:hover {
    background-color: #009cb2;
    color: #FAB075;

}
.btn-tour,
.btn-tour:focus,
.btn-tour:active,
.btn-tour:visited {
    color:$primary;
    margin-left: 20px;
    text-transform: uppercase;
    font-size: 19px;
    font-weight: 500;
    background: transparent;
    transition: all 1s;
    -webkit-transition: all 1s;
    -moz-transition: all 1s;
     -o-transition: all 1s;
    
}

.btn-download {
    margin-bottom: 15px !important;
    display: block;
    margin: 0 auto;
    max-width: 175px;
}


/* =========== Form Area =========== */

.form-area {padding: 60px 0px;
			
			@media (max-width: 1024px){
					 text-align: center;
				   }

			@media (max-width: 1199px) and (orientation: landscape) {

				margin-bottom: 0px;
			}
	}

.form-area h1 {color: $primary;
			   font-size: 4em;
			   position: relative;
			   margin-bottom: 40px;
			
			@media (max-width: 1199px) {
					font-size: 3em;

				   }

}

.form-area p {color: $secondary;
			  line-height: 40px;
			  font-size: 1.5em;

}

.form-area img {padding-bottom: 0px;

				@media (max-width: 767px) {

					padding: 50px 0px;
				}

}

/* =========== Features =========== */

.features {
			background-image: url("../img/features-bg.png");
			background-attachment: scroll;
			background-repeat: no-repeat;
			background-position: 100% 40%;
			z-index: -1000;
			background-size: cover;
			-moz-background-size: cover;
			-o-background-size: cover;
			-webkit-background-size: cover; 
			border-top: 15px solid #009cb2;
			border-bottom: 15px solid #009cb2;


			@media (max-width: 1199px) {
				background-position: 50% 40%;

			}
}

.features-overlay { background-color: rgba(31, 31, 31, 0.75);
    				padding: 150px 0px;
					margin: 0px;
    				box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
      				border: 0px !important;
					  
					@media (max-width: 767px){
				 	padding: 50px 0px;

			 }

}

.features h1 {color: $primary;
			   font-size: 4em;
			   text-align: center;
			   position: relative;
			   margin-bottom: 100px;
}
			   

.features h3 {color: $primary;
			   font-size: 2.5em;
			   position: relative;
			   margin-bottom: 40px;
			   
			   	@media (max-width: 1199px) {
					font-size: 2.25em;

				   }
			   
			   }

.features p {color: $wht;
			  line-height: 25px;
			  font-size: 1.25em;

}

.features div i {font-size: 8em;
				 display: block;
				 margin: 0 auto;
				 color: #FAB075;
			
			@media (max-width: 767px){
				 	font-size: 5em;
					padding-top: 25px;

			 }
}

.join-now-btn {margin-top: 150px;}

/* =========== MAKE A MATCH =========== */

.make-a-match {padding: 50px;
			   @media (max-width: 1199px) {
				  
				  padding: 15px;

			   }
}

.make-a-match h1 {color: $primary;
			   font-size: 4em;
			   text-align: center;
			   position: relative;
			   margin-bottom: 100px;

			   @media (max-width: 767px) {

				   margin-bottom: 0px;
			   }
				@media (max-width: 1024px) {

				   margin-bottom: 25px;
			   }
	}
			

.make-a-match h3 {color: $primary;
			   font-size: 2.5em;
			   position: relative;
			   margin-bottom: 10px;
			
			   @media (max-width: 1024px) {
					
					text-align: center;

				   }
			   
			   }

.make-a-match p {color: $wht;
			  line-height: 25px;
			  font-size: 1.25em;
			  color:$secondary;
			  
			  @media (max-width: 1024px) {
					
					text-align: center;
					margin-bottom: 50px;

			  }

}

.make-a-match img {
				   box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
				   max-width: 350px;
				   margin-top: 25px;

				   
				   @media (max-width: 1024px) {
						   max-width: 250px;
						   display: block;
						   margin: 0 auto;
				   }

}


/* =========== RECENT MEMBERS =========== */

.recent-members {
				 background-image: url("../img/recent-members-background.jpg");
			background-attachment: scroll;
			background-repeat: no-repeat;
			background-position: 50% 50%;
			z-index: -1000;
			background-size: cover;
			-moz-background-size: cover;
			-o-background-size: cover;
			-webkit-background-size: cover; 
}

.recent-member-overlay { background-color: rgba(31, 31, 31, 0.55);
    				padding: 25px 0px;
					margin: 0;
    				box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
      				border: 0px !important;
					  
			 }


.recent-members h1 {color: $wht;
			   font-size: 3em;
			   text-align: center;
			   position: relative;
			   margin-top: 50px;
			   text-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);

				   @media (max-width: 1024px){
					   margin-bottom: 50px;

				   }

				
	}
			   

.recent-members h3 {color: $primary;
			   font-size: 2.5em;
			   position: relative;
			   text-align: center;}

.recent-members p {color: $wht;
			  line-height: 25px;
			  font-size: 1.25em;
			  color:$secondary;

}

.recent-members img {box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
				   	max-width: 150px;

				 	 @media (max-width: 767px){
					   display: block;
					   margin: 0 auto;
					   max-width: 250px;

				   }
				  	@media (max-width: 1024px){
					   display: block;
					   margin: 0 auto;
					   width: 100%;
					   max-width: 250px;

				   }

	}	

